@charset "UTF-8";
/**/
.buyIt {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  font-weight: bold;
  color: #fff;
  letter-spacing: -0.02em;
  width: 20rem;
  border-radius: 0px;
  line-height: 1.6;
  font-size: 20px;
  background: url(https://talex.co.jp/img/ec/logo_oznis-mark.png) no-repeat left center;
  background-size: 28px;
  background-position: left 16px center;
  background-color: #000000;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.buyIt:link, .buyIt:visited {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .buyIt {
    max-width: 90%;
    margin: auto;
  }
}

.cf {
  *zoom: 1;
}

.cf::before, .cf::after {
  content: " ";
  display: table;
}

.cf::after {
  clear: both;
}

@media screen and (min-width: 768px) {
  .cf-mdMin {
    *zoom: 1;
  }
  .cf-mdMin::before, .cf-mdMin::after {
    content: " ";
    display: table;
  }
  .cf-mdMin::after {
    clear: both;
  }
}

@media screen and (max-width: 767px) {
  .cf-mdMax {
    *zoom: 1;
  }
  .cf-mdMax::before, .cf-mdMax::after {
    content: " ";
    display: table;
  }
  .cf-mdMax::after {
    clear: both;
  }
}

.hr {
  border: none;
  border-color: #000;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 0;
}

.hr--dotted {
  border-bottom-style: dotted;
}

.hr--dashed {
  border-bottom-style: dashed;
}

.hr--gray {
  border-color: #ccc;
}

.hr--gray02 {
  border-color: #777;
}

.imgMax {
  width: 100%;
  height: auto;
}

.imgMaxWidth {
  max-width: 100%;
}

.bgLabel {
  padding: .3em 1.5em;
  border-radius: 1000px;
  background-color: #000;
}

.l-inner {
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  .l-inner {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .l-inner-mdMin {
    margin: 0 auto;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-inner-mdMax {
    margin: 0 auto;
    padding: 0 20px;
  }
}

.l-flexZero {
  flex: 0 0 auto;
}

@media screen and (min-width: 768px) {
  .l-flexZero-mdMin {
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-flexZero-mdMax {
    flex: 0 0 auto;
  }
}

.l-flexGlow {
  flex: 1 0 auto;
}

@media screen and (min-width: 768px) {
  .l-flexGlow-mdMin {
    flex: 1 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-flexGlow-mdMax {
    flex: 1 0 auto;
  }
}

.l-flexShrink {
  flex: 0 1 auto;
}

@media screen and (min-width: 768px) {
  .l-flexShrink-mdMin {
    flex: 0 1 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-flexShrink-mdMax {
    flex: 0 1 auto;
  }
}

.l-flexMax {
  flex: 1 1 auto;
}

@media screen and (min-width: 768px) {
  .l-flexMax-mdMin {
    flex: 1 1 auto;
  }
}

@media screen and (max-width: 767px) {
  .l-flexMax-mdMax {
    flex: 1 1 auto;
  }
}

.l-column {
  flex: 1 1 1160px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .l-column {
    max-width: 1160px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-column {
    padding: 0 20px;
  }
}

.l-column--long {
  flex-basis: 1360px;
  max-width: 1360px;
}

.l-column--short {
  flex-basis: 840px;
  max-width: 840px;
}

.l-mwLong {
  max-width: 1320px;
  margin: 0 auto;
}

.l-mwShort {
  max-width: 800px;
  margin: 0 auto;
}

.l-mwMax {
  max-width: 1920px;
  margin: 0 auto;
}

.l-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .l-row-mdMin {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

.l-halfTile {
  width: 50%;
}

@media screen and (min-width: 768px) {
  .l-halfColumn-mdMin {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .l-halfColumn-mdMin:nth-child(2n), .l-halfColumn-mdMin:last-child {
    margin-right: 0;
  }
  .l-halfColumn-mdMin:last-child, .l-halfColumn-mdMin:nth-last-child(2).l-halfColumn-mdMin:nth-child(2n+1) {
    margin-bottom: 0;
  }
  .u-fdRowReverse-mdMin .l-halfColumn-mdMin:nth-child(1), .u-fdRowReverse-mdMin .l-halfColumn-mdMin:last-child {
    margin-right: 0;
  }
  .u-fdRowReverse-mdMin .l-halfColumn-mdMin:nth-child(2) {
    margin-right: 40px;
  }
}

.l-halfBox {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  margin-right: 40px;
  margin-bottom: 40px;
}

.l-halfBox:nth-child(2n), .l-halfBox:last-child {
  margin-right: 0;
}

.l-halfBox:last-child, .l-halfBox:nth-last-child(2).l-halfBox:nth-child(2n+1) {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-halfBox {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-halfBox:nth-child(2n), .l-halfBox:last-child {
    margin-right: 0;
  }
  .l-halfBox:last-child, .l-halfBox:nth-last-child(2).l-halfBox:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-halfBox-mdMin {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .l-halfBox-mdMin:nth-child(2n), .l-halfBox-mdMin:last-child {
    margin-right: 0;
  }
  .l-halfBox-mdMin:last-child, .l-halfBox-mdMin:nth-last-child(2).l-halfBox-mdMin:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-halfBox-mdMax {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-halfBox-mdMax:nth-child(2n), .l-halfBox-mdMax:last-child {
    margin-right: 0;
  }
  .l-halfBox-mdMax:last-child, .l-halfBox-mdMax:nth-last-child(2).l-halfBox-mdMax:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .l-halfBox-lgMin {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .l-halfBox-lgMin:nth-child(2n), .l-halfBox-lgMin:last-child {
    margin-right: 0;
  }
  .l-halfBox-lgMin:last-child, .l-halfBox-lgMin:nth-last-child(2).l-halfBox-lgMin:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .l-halfBox-lgMax {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-halfBox-lgMax:nth-child(2n), .l-halfBox-lgMax:last-child {
    margin-right: 0;
  }
  .l-halfBox-lgMax:last-child, .l-halfBox-lgMax:nth-last-child(2).l-halfBox-lgMax:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

.l-halfBox02 {
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
  margin-right: 20px;
  margin-bottom: 20px;
}

.l-halfBox02:nth-child(2n), .l-halfBox02:last-child {
  margin-right: 0;
}

.l-halfBox02:last-child, .l-halfBox02:nth-last-child(2).l-halfBox02:nth-child(2n+1) {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-halfBox02 {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-halfBox02:nth-child(2n), .l-halfBox02:last-child {
    margin-right: 0;
  }
  .l-halfBox02:last-child, .l-halfBox02:nth-last-child(2).l-halfBox02:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

.l-halfBox03 {
  flex: 1 1 calc(50% - 5px);
  max-width: calc(50% - 5px);
  margin-right: 10px;
  margin-bottom: 5px;
}

.l-halfBox03:nth-child(2n), .l-halfBox03:last-child {
  margin-right: 0;
}

.l-halfBox03:last-child, .l-halfBox03:nth-last-child(2).l-halfBox03:nth-child(2n+1) {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-halfBox03 {
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .l-halfBox03:nth-child(2n), .l-halfBox03:last-child {
    margin-right: 0;
  }
  .l-halfBox03:last-child, .l-halfBox03:nth-last-child(2).l-halfBox03:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-halfBox03-mdMin {
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .l-halfBox03-mdMin:nth-child(2n), .l-halfBox03-mdMin:last-child {
    margin-right: 0;
  }
  .l-halfBox03-mdMin:last-child, .l-halfBox03-mdMin:nth-last-child(2).l-halfBox03-mdMin:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-halfBox03-mdMax {
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .l-halfBox03-mdMax:nth-child(2n), .l-halfBox03-mdMax:last-child {
    margin-right: 0;
  }
  .l-halfBox03-mdMax:last-child, .l-halfBox03-mdMax:nth-last-child(2).l-halfBox03-mdMax:nth-child(2n+1) {
    margin-bottom: 0;
  }
}

.l-tripartitionBox {
  flex: 1 1 calc(33.33% - 26.66667px);
  max-width: calc(33.33% - 26.66667px);
  margin-right: 40px;
  margin-bottom: 40px;
}

.l-tripartitionBox:nth-child(3n), .l-tripartitionBox:last-child {
  margin-right: 0;
}

.l-tripartitionBox:last-child, .l-tripartitionBox:nth-last-child(2).l-tripartitionBox:nth-child(3n+2), .l-tripartitionBox:nth-last-child(2).l-tripartitionBox:nth-child(3n+1), .l-tripartitionBox:nth-last-child(3).l-tripartitionBox:nth-child(3n+1) {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-tripartitionBox {
    flex: 1 1 calc(33.33% - 13.33333px);
    max-width: calc(33.33% - 13.33333px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-tripartitionBox:nth-child(3n), .l-tripartitionBox:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox:last-child, .l-tripartitionBox:nth-last-child(2).l-tripartitionBox:nth-child(3n+2), .l-tripartitionBox:nth-last-child(2).l-tripartitionBox:nth-child(3n+1), .l-tripartitionBox:nth-last-child(3).l-tripartitionBox:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-tripartitionBox-mdMin {
    flex: 1 1 calc(33.33% - 26.66667px);
    max-width: calc(33.33% - 26.66667px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .l-tripartitionBox-mdMin:nth-child(3n), .l-tripartitionBox-mdMin:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox-mdMin:last-child, .l-tripartitionBox-mdMin:nth-last-child(2).l-tripartitionBox-mdMin:nth-child(3n+2), .l-tripartitionBox-mdMin:nth-last-child(2).l-tripartitionBox-mdMin:nth-child(3n+1), .l-tripartitionBox-mdMin:nth-last-child(3).l-tripartitionBox-mdMin:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-tripartitionBox-mdMax {
    flex: 1 1 calc(33.33% - 13.33333px);
    max-width: calc(33.33% - 13.33333px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-tripartitionBox-mdMax:nth-child(3n), .l-tripartitionBox-mdMax:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox-mdMax:last-child, .l-tripartitionBox-mdMax:nth-last-child(2).l-tripartitionBox-mdMax:nth-child(3n+2), .l-tripartitionBox-mdMax:nth-last-child(2).l-tripartitionBox-mdMax:nth-child(3n+1), .l-tripartitionBox-mdMax:nth-last-child(3).l-tripartitionBox-mdMax:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .l-tripartitionBox-lgMin {
    flex: 1 1 calc(33.33% - 26.66667px);
    max-width: calc(33.33% - 26.66667px);
    margin-right: 40px;
    margin-bottom: 40px;
  }
  .l-tripartitionBox-lgMin:nth-child(3n), .l-tripartitionBox-lgMin:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox-lgMin:last-child, .l-tripartitionBox-lgMin:nth-last-child(2).l-tripartitionBox-lgMin:nth-child(3n+2), .l-tripartitionBox-lgMin:nth-last-child(2).l-tripartitionBox-lgMin:nth-child(3n+1), .l-tripartitionBox-lgMin:nth-last-child(3).l-tripartitionBox-lgMin:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1023px) {
  .l-tripartitionBox-lgMax {
    flex: 1 1 calc(33.33% - 13.33333px);
    max-width: calc(33.33% - 13.33333px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-tripartitionBox-lgMax:nth-child(3n), .l-tripartitionBox-lgMax:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox-lgMax:last-child, .l-tripartitionBox-lgMax:nth-last-child(2).l-tripartitionBox-lgMax:nth-child(3n+2), .l-tripartitionBox-lgMax:nth-last-child(2).l-tripartitionBox-lgMax:nth-child(3n+1), .l-tripartitionBox-lgMax:nth-last-child(3).l-tripartitionBox-lgMax:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

.l-tripartitionBox02 {
  flex: 1 1 calc(33.33% - 13.33333px);
  max-width: calc(33.33% - 13.33333px);
  margin-right: 20px;
  margin-bottom: 20px;
}

.l-tripartitionBox02:nth-child(3n), .l-tripartitionBox02:last-child {
  margin-right: 0;
}

.l-tripartitionBox02:last-child, .l-tripartitionBox02:nth-last-child(2).l-tripartitionBox02:nth-child(3n+2), .l-tripartitionBox02:nth-last-child(2).l-tripartitionBox02:nth-child(3n+1), .l-tripartitionBox02:nth-last-child(3).l-tripartitionBox02:nth-child(3n+1) {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-tripartitionBox02 {
    flex: 1 1 calc(33.33% - 13.33333px);
    max-width: calc(33.33% - 13.33333px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-tripartitionBox02:nth-child(3n), .l-tripartitionBox02:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox02:last-child, .l-tripartitionBox02:nth-last-child(2).l-tripartitionBox02:nth-child(3n+2), .l-tripartitionBox02:nth-last-child(2).l-tripartitionBox02:nth-child(3n+1), .l-tripartitionBox02:nth-last-child(3).l-tripartitionBox02:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-tripartitionBox02-mdMin {
    flex: 1 1 calc(33.33% - 13.33333px);
    max-width: calc(33.33% - 13.33333px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-tripartitionBox02-mdMin:nth-child(3n), .l-tripartitionBox02-mdMin:last-child {
    margin-right: 0;
  }
  .l-tripartitionBox02-mdMin:last-child, .l-tripartitionBox02-mdMin:nth-last-child(2).l-tripartitionBox02-mdMin:nth-child(3n+2), .l-tripartitionBox02-mdMin:nth-last-child(2).l-tripartitionBox02-mdMin:nth-child(3n+1), .l-tripartitionBox02-mdMin:nth-last-child(3).l-tripartitionBox02-mdMin:nth-child(3n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-quarterBox-mdMin {
    flex: 1 1 calc(25.00% - 15px);
    max-width: calc(25.00% - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-quarterBox-mdMin:nth-child(4n), .l-quarterBox-mdMin:last-child {
    margin-right: 0;
  }
  .l-quarterBox-mdMin:last-child, .l-quarterBox-mdMin:nth-last-child(2).l-quarterBox-mdMin:nth-child(4n+1), .l-quarterBox-mdMin:nth-last-child(2).l-quarterBox-mdMin:nth-child(4n+2), .l-quarterBox-mdMin:nth-last-child(2).l-quarterBox-mdMin:nth-child(4n+3), .l-quarterBox-mdMin:nth-last-child(3).l-quarterBox-mdMin:nth-child(4n+1), .l-quarterBox-mdMin:nth-last-child(3).l-quarterBox-mdMin:nth-child(4n+2), .l-quarterBox-mdMin:nth-last-child(4).l-quarterBox-mdMin:nth-child(4n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .l-quarterBox-lgMin {
    flex: 1 1 calc(25.00% - 15px);
    max-width: calc(25.00% - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-quarterBox-lgMin:nth-child(4n), .l-quarterBox-lgMin:last-child {
    margin-right: 0;
  }
  .l-quarterBox-lgMin:last-child, .l-quarterBox-lgMin:nth-last-child(2).l-quarterBox-lgMin:nth-child(4n+1), .l-quarterBox-lgMin:nth-last-child(2).l-quarterBox-lgMin:nth-child(4n+2), .l-quarterBox-lgMin:nth-last-child(2).l-quarterBox-lgMin:nth-child(4n+3), .l-quarterBox-lgMin:nth-last-child(3).l-quarterBox-lgMin:nth-child(4n+1), .l-quarterBox-lgMin:nth-last-child(3).l-quarterBox-lgMin:nth-child(4n+2), .l-quarterBox-lgMin:nth-last-child(4).l-quarterBox-lgMin:nth-child(4n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .l-quarterBox-xlMin {
    flex: 1 1 calc(25.00% - 15px);
    max-width: calc(25.00% - 15px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-quarterBox-xlMin:nth-child(4n), .l-quarterBox-xlMin:last-child {
    margin-right: 0;
  }
  .l-quarterBox-xlMin:last-child, .l-quarterBox-xlMin:nth-last-child(2).l-quarterBox-xlMin:nth-child(4n+1), .l-quarterBox-xlMin:nth-last-child(2).l-quarterBox-xlMin:nth-child(4n+2), .l-quarterBox-xlMin:nth-last-child(2).l-quarterBox-xlMin:nth-child(4n+3), .l-quarterBox-xlMin:nth-last-child(3).l-quarterBox-xlMin:nth-child(4n+1), .l-quarterBox-xlMin:nth-last-child(3).l-quarterBox-xlMin:nth-child(4n+2), .l-quarterBox-xlMin:nth-last-child(4).l-quarterBox-xlMin:nth-child(4n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-fifthBox-mdMin {
    flex: 1 1 calc(20.00% - 16px);
    max-width: calc(20.00% - 16px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-fifthBox-mdMin:nth-child(5n), .l-fifthBox-mdMin:last-child {
    margin-right: 0;
  }
  .l-fifthBox-mdMin:last-child, .l-fifthBox-mdMin:nth-last-child(2).l-fifthBox-mdMin:nth-child(5n+1), .l-fifthBox-mdMin:nth-last-child(2).l-fifthBox-mdMin:nth-child(5n+2), .l-fifthBox-mdMin:nth-last-child(2).l-fifthBox-mdMin:nth-child(5n+3), .l-fifthBox-mdMin:nth-last-child(2).l-fifthBox-mdMin:nth-child(5n+4), .l-fifthBox-mdMin:nth-last-child(3).l-fifthBox-mdMin:nth-child(5n+1), .l-fifthBox-mdMin:nth-last-child(3).l-fifthBox-mdMin:nth-child(5n+2), .l-fifthBox-mdMin:nth-last-child(3).l-fifthBox-mdMin:nth-child(5n+3), .l-fifthBox-mdMin:nth-last-child(4).l-fifthBox-mdMin:nth-child(5n+1), .l-fifthBox-mdMin:nth-last-child(4).l-fifthBox-mdMin:nth-child(5n+2), .l-fifthBox-mdMin:nth-last-child(5).l-fifthBox-mdMin:nth-child(5n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .l-fifthBox-lgMin {
    flex: 1 1 calc(20.00% - 16px);
    max-width: calc(20.00% - 16px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-fifthBox-lgMin:nth-child(5n), .l-fifthBox-lgMin:last-child {
    margin-right: 0;
  }
  .l-fifthBox-lgMin:last-child, .l-fifthBox-lgMin:nth-last-child(2).l-fifthBox-lgMin:nth-child(5n+1), .l-fifthBox-lgMin:nth-last-child(2).l-fifthBox-lgMin:nth-child(5n+2), .l-fifthBox-lgMin:nth-last-child(2).l-fifthBox-lgMin:nth-child(5n+3), .l-fifthBox-lgMin:nth-last-child(2).l-fifthBox-lgMin:nth-child(5n+4), .l-fifthBox-lgMin:nth-last-child(3).l-fifthBox-lgMin:nth-child(5n+1), .l-fifthBox-lgMin:nth-last-child(3).l-fifthBox-lgMin:nth-child(5n+2), .l-fifthBox-lgMin:nth-last-child(3).l-fifthBox-lgMin:nth-child(5n+3), .l-fifthBox-lgMin:nth-last-child(4).l-fifthBox-lgMin:nth-child(5n+1), .l-fifthBox-lgMin:nth-last-child(4).l-fifthBox-lgMin:nth-child(5n+2), .l-fifthBox-lgMin:nth-last-child(5).l-fifthBox-lgMin:nth-child(5n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .l-fifthBox-xlMin {
    flex: 1 1 calc(20.00% - 16px);
    max-width: calc(20.00% - 16px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-fifthBox-xlMin:nth-child(5n), .l-fifthBox-xlMin:last-child {
    margin-right: 0;
  }
  .l-fifthBox-xlMin:last-child, .l-fifthBox-xlMin:nth-last-child(2).l-fifthBox-xlMin:nth-child(5n+1), .l-fifthBox-xlMin:nth-last-child(2).l-fifthBox-xlMin:nth-child(5n+2), .l-fifthBox-xlMin:nth-last-child(2).l-fifthBox-xlMin:nth-child(5n+3), .l-fifthBox-xlMin:nth-last-child(2).l-fifthBox-xlMin:nth-child(5n+4), .l-fifthBox-xlMin:nth-last-child(3).l-fifthBox-xlMin:nth-child(5n+1), .l-fifthBox-xlMin:nth-last-child(3).l-fifthBox-xlMin:nth-child(5n+2), .l-fifthBox-xlMin:nth-last-child(3).l-fifthBox-xlMin:nth-child(5n+3), .l-fifthBox-xlMin:nth-last-child(4).l-fifthBox-xlMin:nth-child(5n+1), .l-fifthBox-xlMin:nth-last-child(4).l-fifthBox-xlMin:nth-child(5n+2), .l-fifthBox-xlMin:nth-last-child(5).l-fifthBox-xlMin:nth-child(5n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-sixthBox-mdMin {
    flex: 1 1 calc(16.66% - 16.66667px);
    max-width: calc(16.66% - 16.66667px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-sixthBox-mdMin:nth-child(6n), .l-sixthBox-mdMin:last-child {
    margin-right: 0;
  }
  .l-sixthBox-mdMin:last-child, .l-sixthBox-mdMin:nth-last-child(2).l-sixthBox-mdMin:nth-child(6n+1), .l-sixthBox-mdMin:nth-last-child(2).l-sixthBox-mdMin:nth-child(6n+2), .l-sixthBox-mdMin:nth-last-child(2).l-sixthBox-mdMin:nth-child(6n+3), .l-sixthBox-mdMin:nth-last-child(2).l-sixthBox-mdMin:nth-child(6n+4), .l-sixthBox-mdMin:nth-last-child(2).l-sixthBox-mdMin:nth-child(6n+5), .l-sixthBox-mdMin:nth-last-child(3).l-sixthBox-mdMin:nth-child(6n+1), .l-sixthBox-mdMin:nth-last-child(3).l-sixthBox-mdMin:nth-child(6n+2), .l-sixthBox-mdMin:nth-last-child(3).l-sixthBox-mdMin:nth-child(6n+3), .l-sixthBox-mdMin:nth-last-child(3).l-sixthBox-mdMin:nth-child(6n+4), .l-sixthBox-mdMin:nth-last-child(4).l-sixthBox-mdMin:nth-child(6n+1), .l-sixthBox-mdMin:nth-last-child(4).l-sixthBox-mdMin:nth-child(6n+2), .l-sixthBox-mdMin:nth-last-child(4).l-sixthBox-mdMin:nth-child(6n+3), .l-sixthBox-mdMin:nth-last-child(5).l-sixthBox-mdMin:nth-child(6n+1), .l-sixthBox-mdMin:nth-last-child(5).l-sixthBox-mdMin:nth-child(6n+2), .l-sixthBox-mdMin:nth-last-child(6).l-sixthBox-mdMin:nth-child(6n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .l-sixthBox-lgMin {
    flex: 1 1 calc(16.66% - 16.66667px);
    max-width: calc(16.66% - 16.66667px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-sixthBox-lgMin:nth-child(6n), .l-sixthBox-lgMin:last-child {
    margin-right: 0;
  }
  .l-sixthBox-lgMin:last-child, .l-sixthBox-lgMin:nth-last-child(2).l-sixthBox-lgMin:nth-child(6n+1), .l-sixthBox-lgMin:nth-last-child(2).l-sixthBox-lgMin:nth-child(6n+2), .l-sixthBox-lgMin:nth-last-child(2).l-sixthBox-lgMin:nth-child(6n+3), .l-sixthBox-lgMin:nth-last-child(2).l-sixthBox-lgMin:nth-child(6n+4), .l-sixthBox-lgMin:nth-last-child(2).l-sixthBox-lgMin:nth-child(6n+5), .l-sixthBox-lgMin:nth-last-child(3).l-sixthBox-lgMin:nth-child(6n+1), .l-sixthBox-lgMin:nth-last-child(3).l-sixthBox-lgMin:nth-child(6n+2), .l-sixthBox-lgMin:nth-last-child(3).l-sixthBox-lgMin:nth-child(6n+3), .l-sixthBox-lgMin:nth-last-child(3).l-sixthBox-lgMin:nth-child(6n+4), .l-sixthBox-lgMin:nth-last-child(4).l-sixthBox-lgMin:nth-child(6n+1), .l-sixthBox-lgMin:nth-last-child(4).l-sixthBox-lgMin:nth-child(6n+2), .l-sixthBox-lgMin:nth-last-child(4).l-sixthBox-lgMin:nth-child(6n+3), .l-sixthBox-lgMin:nth-last-child(5).l-sixthBox-lgMin:nth-child(6n+1), .l-sixthBox-lgMin:nth-last-child(5).l-sixthBox-lgMin:nth-child(6n+2), .l-sixthBox-lgMin:nth-last-child(6).l-sixthBox-lgMin:nth-child(6n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1280px) {
  .l-sixthBox-xlMin {
    flex: 1 1 calc(16.66% - 16.66667px);
    max-width: calc(16.66% - 16.66667px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .l-sixthBox-xlMin:nth-child(6n), .l-sixthBox-xlMin:last-child {
    margin-right: 0;
  }
  .l-sixthBox-xlMin:last-child, .l-sixthBox-xlMin:nth-last-child(2).l-sixthBox-xlMin:nth-child(6n+1), .l-sixthBox-xlMin:nth-last-child(2).l-sixthBox-xlMin:nth-child(6n+2), .l-sixthBox-xlMin:nth-last-child(2).l-sixthBox-xlMin:nth-child(6n+3), .l-sixthBox-xlMin:nth-last-child(2).l-sixthBox-xlMin:nth-child(6n+4), .l-sixthBox-xlMin:nth-last-child(2).l-sixthBox-xlMin:nth-child(6n+5), .l-sixthBox-xlMin:nth-last-child(3).l-sixthBox-xlMin:nth-child(6n+1), .l-sixthBox-xlMin:nth-last-child(3).l-sixthBox-xlMin:nth-child(6n+2), .l-sixthBox-xlMin:nth-last-child(3).l-sixthBox-xlMin:nth-child(6n+3), .l-sixthBox-xlMin:nth-last-child(3).l-sixthBox-xlMin:nth-child(6n+4), .l-sixthBox-xlMin:nth-last-child(4).l-sixthBox-xlMin:nth-child(6n+1), .l-sixthBox-xlMin:nth-last-child(4).l-sixthBox-xlMin:nth-child(6n+2), .l-sixthBox-xlMin:nth-last-child(4).l-sixthBox-xlMin:nth-child(6n+3), .l-sixthBox-xlMin:nth-last-child(5).l-sixthBox-xlMin:nth-child(6n+1), .l-sixthBox-xlMin:nth-last-child(5).l-sixthBox-xlMin:nth-child(6n+2), .l-sixthBox-xlMin:nth-last-child(6).l-sixthBox-xlMin:nth-child(6n+1) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .l-floatLeft {
    float: left;
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-floatLeft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .l-floatLeft-mdMin {
    float: left;
    margin-right: 40px;
  }
}

@media screen and (min-width: 768px) {
  .l-floatRight {
    float: right;
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  .l-floatRight {
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .l-floatRight-mdMin {
    float: right;
    margin-left: 40px;
  }
}

.l-mw95pct {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.l-mw90pct {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.l-mw85pct {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.l-mw80pct {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.l-mw75pct {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.l-mw70pct {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .l-mw70pct-mdMin {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .l-mw70pct-mdMax {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-mw65pct {
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .l-mw65pct-mdMin {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .l-mw65pct-mdMax {
    max-width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-mw60pct {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.rollOver {
  transition: opacity 0.3s;
}

.rollOver img {
  background-color: rgba(255, 255, 255, 0.01);
}

.rollOver:hover {
  opacity: 0.7;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.linkText {
  text-decoration: underline;
}

.linkText:hover {
  text-decoration: none;
}

.smallText {
  font-size: 0.625rem;
}

@media screen and (max-width: 767px) {
  .smallText {
    font-size: 0.75rem;
  }
}

.smallText02 {
  font-size: 0.75rem;
}

@media screen and (max-width: 767px) {
  .smallText02 {
    font-size: 0.75rem;
  }
}

.smallText03 {
  font-size: 0.8125rem;
}

@media screen and (max-width: 767px) {
  .smallText03 {
    font-size: 0.75rem;
  }
}

.mediumText {
  font-size: 0.875rem;
}

@media screen and (max-width: 767px) {
  .mediumText {
    font-size: 0.8125rem;
  }
}

.mediumText02 {
  font-size: 0.9375rem;
}

@media screen and (max-width: 767px) {
  .mediumText02 {
    font-size: 0.8125rem;
  }
}

.normalText {
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .normalText {
    font-size: 0.875rem;
  }
}

.normalText02 {
  font-size: 1.0625rem;
}

@media screen and (max-width: 767px) {
  .normalText02 {
    font-size: 0.875rem;
  }
}

.largeText {
  font-size: 1.125rem;
}

@media screen and (max-width: 767px) {
  .largeText {
    font-size: 1rem;
  }
}

.largeText02 {
  font-size: 1.1875rem;
}

@media screen and (max-width: 767px) {
  .largeText02 {
    font-size: 1rem;
  }
}

.largeText03 {
  font-size: 1.25rem;
}

@media screen and (max-width: 767px) {
  .largeText03 {
    font-size: 1rem;
  }
}

.exLargeText {
  font-size: 1.3125rem;
}

@media screen and (max-width: 767px) {
  .exLargeText {
    font-size: 1.125rem;
  }
}

.exLargeText02 {
  font-size: 1.375rem;
}

@media screen and (max-width: 767px) {
  .exLargeText02 {
    font-size: 1.125rem;
  }
}

.xxLargeText {
  font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
  .xxLargeText {
    font-size: 1.25rem;
  }
}

.xxLargeText02 {
  font-size: 1.75rem;
}

@media screen and (max-width: 767px) {
  .xxLargeText02 {
    font-size: 1.25rem;
  }
}

.xxxLargeText {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .xxxLargeText {
    font-size: 1.375rem;
  }
}

.xxxLargeText02 {
  font-size: 2.25rem;
}

@media screen and (max-width: 767px) {
  .xxxLargeText02 {
    font-size: 1.375rem;
  }
}

@media screen and (min-width: 768px) {
  .smallText-mdMin {
    font-size: 0.625rem;
  }
  .smallText02-mdMin {
    font-size: 0.75rem;
  }
  .smallText03-mdMin {
    font-size: 0.8125rem;
  }
  .mediumText-mdMin {
    font-size: 0.875rem;
  }
  .mediumText02-mdMin {
    font-size: 0.9375rem;
  }
  .normalText-mdMin {
    font-size: 1rem;
  }
  .normalText02-mdMin {
    font-size: 1.0625rem;
  }
  .largeText-mdMin {
    font-size: 1.125rem;
  }
  .largeText02-mdMin {
    font-size: 1.1875rem;
  }
  .largeText03-mdMin {
    font-size: 1.25rem;
  }
  .exLargeText-mdMin {
    font-size: 1.3125rem;
  }
  .exLargeText02-mdMin {
    font-size: 1.375rem;
  }
  .xxLargeText-mdMin {
    font-size: 1.5rem;
  }
  .xxLargeText02-mdMin {
    font-size: 1.75rem;
  }
  .xxxLargeText-mdMin {
    font-size: 2rem;
  }
  .xxxLargeText02-mdMin {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .smallText-mdMax {
    font-size: 0.75rem;
  }
  .mediumText-mdMax {
    font-size: 0.8125rem;
  }
  .normalText-mdMax {
    font-size: 0.875rem;
  }
  .largeText-mdMax {
    font-size: 1rem;
  }
  .exLargeText-mdMax {
    font-size: 1.125rem;
  }
  .xxLargeText-mdMax {
    font-size: 1.25rem;
  }
  .xxxLargeText-mdMax {
    font-size: 1.375rem;
  }
}

.bgColorWhite {
  background-color: #fff;
}

.bgColorBlack {
  background-color: #000;
}

.bgColorBlackLight {
  background-color: #333;
}

.bgColorBase {
  background-color: #b60000;
}

.bgColorGray {
  background-color: #ccc;
}

.bgColorGrayLight {
  background-color: #F3F3F3;
}

.bgColorGrayLight02 {
  background-color: #eee;
}

.bgColorBeige {
  background-color: #f4f2ed;
}

.bgColorBeigeLight {
  background-color: #FDFCFB;
}

@media screen and (min-width: 768px) {
  .bgColorGray-mdMin {
    background-color: #ccc;
  }
  .bgColorGrayLight-mdMin {
    background-color: #F3F3F3;
  }
  .bgColorGrayLight02-mdMin {
    background-color: #eee;
  }
}

@media screen and (max-width: 767px) {
  .bgColorGray-mdMax {
    background-color: #ccc;
  }
  .bgColorGrayLight-mdMax {
    background-color: #F3F3F3;
  }
  .bgColorGrayLight02-mdMax {
    background-color: #eee;
  }
}

.p-MagIndexLogo {
  position: relative;
}

.p-MagIndexLogo__items {
  position: absolute;
  bottom: 0;
  right: 35px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .p-MagIndexLogo__items {
    max-width: 68px;
    flex-wrap: wrap;
    right: 29px;
  }
}

.p-MagIndexLogo__item {
  flex: 0 0 55px;
  margin: 4px 2px;
}

@media screen and (max-width: 767px) {
  .p-MagIndexLogo__item {
    flex-basis: 30px;
  }
}

.p-MagIndexLogo__item--reddot {
  flex-basis: 94px;
}

@media screen and (max-width: 767px) {
  .p-MagIndexLogo__item--reddot {
    flex-basis: 64px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-MagScene {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .p-MagScene {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.p-MagScene__item {
  position: relative;
}

@media screen and (min-width: 768px) {
  .p-MagScene__item {
    flex: 0 0 33.33%;
  }
}

.p-MagScene__text {
  text-align: center;
  font-size: 0.875rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 80%;
  padding: .3em 1em;
  line-height: 1.3;
  color: #fff;
  background-color: #000;
}

.p-Mag {
  position: relative;
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .p-Mag__inner {
    width: 100%;
    height: 66.66vw;
  }
}

@media screen and (min-width: 768px) {
  .p-Mag__inner {
    padding-top: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
}

@media screen and (min-width: 768px) {
  .p-magBreadCrumb {
    position: relative;
    width: 87.5%;
    max-width: 70rem;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .p-MagHeadWrap {
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .p-MagHead {
    position: absolute;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-MagHead__logo {
    width: 87.5%;
    max-width: 70rem;
    margin: 0 auto 0;
    padding-top: 80px;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-MagMv {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-MagContent {
    width: 87.5%;
    max-width: 70rem;
    margin: auto;
  }
}

.p-Mag01 .accessory .img-wrap {
  height: auto !important;
}

.p-Mag01Head {
  height: auto !important;
}

@media screen and (max-width: 767px) {
  .p-Mag01Movie {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
  }
  .p-Mag01Movie__item {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.05);
  }
}

.p-Mag01Ppl {
  overflow: hidden;
  padding: 50px;
  border: 2px dashed #1591B7;
  border-radius: 15px;
}

@media screen and (min-width: 768px) {
  .p-Mag01Ppl {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .p-Mag01Ppl {
    padding: 20px;
    border-radius: 8px;
  }
}

.p-Mag01Ppl__item {
  flex: 0 1 48%;
}

.p-Mag01Ppl__item--body {
  padding: 30px;
  background-color: #CAE0E8;
}

div[id^="product-component-"],
div[id*="product-component-"] {
  max-width: initial !important;
}

@media screen and (min-width: 768px) {
  #root.feature-contents section#top .content.golf {
    right: 0;
    left: inherit;
  }
}

.box-auto {
  margin: auto;
}

@media screen and (max-width: 767px) {
  .box-auto {
    margin: auto;
  }
}

.block-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 767px) {
  .block-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.blockT-auto {
  margin-top: auto;
}

@media screen and (max-width: 767px) {
  .blockT-auto {
    margin-top: auto;
  }
}

.blockB-auto {
  margin-bottom: auto;
}

@media screen and (max-width: 767px) {
  .blockB-auto {
    margin-bottom: auto;
  }
}

.box-base {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .box-base {
    margin: 20px;
  }
}

.block-base {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .block-base {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blockT-base {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .blockT-base {
    margin-top: 20px;
  }
}

.blockR-base {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .blockR-base {
    margin-right: 20px;
  }
}

.blockB-base {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .blockB-base {
    margin-bottom: 20px;
  }
}

.blockL-base {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .blockL-base {
    margin-left: 20px;
  }
}

.box-section03 {
  margin: 150px;
}

@media screen and (max-width: 767px) {
  .box-section03 {
    margin: 100px;
  }
}

.block-section03 {
  margin-top: 150px;
  margin-bottom: 150px;
}

@media screen and (max-width: 767px) {
  .block-section03 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.blockT-section03 {
  margin-top: 150px;
}

@media screen and (max-width: 767px) {
  .blockT-section03 {
    margin-top: 100px;
  }
}

.blockB-section03 {
  margin-bottom: 150px;
}

@media screen and (max-width: 767px) {
  .blockB-section03 {
    margin-bottom: 100px;
  }
}

.box-section02 {
  margin: 120px;
}

@media screen and (max-width: 767px) {
  .box-section02 {
    margin: 75px;
  }
}

.block-section02 {
  margin-top: 120px;
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .block-section02 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
}

.blockT-section02 {
  margin-top: 120px;
}

@media screen and (max-width: 767px) {
  .blockT-section02 {
    margin-top: 75px;
  }
}

.blockB-section02 {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .blockB-section02 {
    margin-bottom: 75px;
  }
}

.box-section {
  margin: 100px;
}

@media screen and (max-width: 767px) {
  .box-section {
    margin: 50px;
  }
}

.block-section {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .block-section {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.blockT-section {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .blockT-section {
    margin-top: 50px;
  }
}

.blockB-section {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .blockB-section {
    margin-bottom: 50px;
  }
}

.box-contents03 {
  margin: 60px;
}

@media screen and (max-width: 767px) {
  .box-contents03 {
    margin: 40px;
  }
}

.block-contents03 {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .block-contents03 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.blockT-contents03 {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .blockT-contents03 {
    margin-top: 40px;
  }
}

.blockR-contents03 {
  margin-right: 60px;
}

@media screen and (max-width: 767px) {
  .blockR-contents03 {
    margin-right: 40px;
  }
}

.blockB-contents03 {
  margin-bottom: 60px;
}

@media screen and (max-width: 767px) {
  .blockB-contents03 {
    margin-bottom: 40px;
  }
}

.blockL-contents03 {
  margin-left: 60px;
}

@media screen and (max-width: 767px) {
  .blockL-contents03 {
    margin-left: 40px;
  }
}

.box-contents02 {
  margin: 50px;
}

@media screen and (max-width: 767px) {
  .box-contents02 {
    margin: 35px;
  }
}

.block-contents02 {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .block-contents02 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.blockT-contents02 {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .blockT-contents02 {
    margin-top: 35px;
  }
}

.blockR-contents02 {
  margin-right: 50px;
}

@media screen and (max-width: 767px) {
  .blockR-contents02 {
    margin-right: 35px;
  }
}

.blockB-contents02 {
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .blockB-contents02 {
    margin-bottom: 35px;
  }
}

.blockL-contents02 {
  margin-left: 50px;
}

@media screen and (max-width: 767px) {
  .blockL-contents02 {
    margin-left: 35px;
  }
}

.box-contents {
  margin: 40px;
}

@media screen and (max-width: 767px) {
  .box-contents {
    margin: 30px;
  }
}

.block-contents {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .block-contents {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.blockT-contents {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .blockT-contents {
    margin-top: 30px;
  }
}

.blockR-contents {
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  .blockR-contents {
    margin-right: 30px;
  }
}

.blockB-contents {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .blockB-contents {
    margin-bottom: 30px;
  }
}

.blockL-contents {
  margin-left: 40px;
}

@media screen and (max-width: 767px) {
  .blockL-contents {
    margin-left: 30px;
  }
}

.box-normal {
  margin: 30px;
}

@media screen and (max-width: 767px) {
  .box-normal {
    margin: 20px;
  }
}

.block-normal {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .block-normal {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.blockT-normal {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .blockT-normal {
    margin-top: 20px;
  }
}

.blockR-normal {
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .blockR-normal {
    margin-right: 20px;
  }
}

.blockB-normal {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .blockB-normal {
    margin-bottom: 20px;
  }
}

.blockL-normal {
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  .blockL-normal {
    margin-left: 20px;
  }
}

.box-semi {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .box-semi {
    margin: 15px;
  }
}

.block-semi {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .block-semi {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.blockT-semi {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .blockT-semi {
    margin-top: 15px;
  }
}

.blockR-semi {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .blockR-semi {
    margin-right: 15px;
  }
}

.blockB-semi {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .blockB-semi {
    margin-bottom: 15px;
  }
}

.blockL-semi {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .blockL-semi {
    margin-left: 15px;
  }
}

.box-small {
  margin: 15px;
}

@media screen and (max-width: 767px) {
  .box-small {
    margin: 10px;
  }
}

.block-small {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .block-small {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.blockT-small {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .blockT-small {
    margin-top: 10px;
  }
}

.blockR-small {
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  .blockR-small {
    margin-right: 10px;
  }
}

.blockB-small {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .blockB-small {
    margin-bottom: 10px;
  }
}

.blockL-small {
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .blockL-small {
    margin-left: 10px;
  }
}

.box-mini {
  margin: 10px;
}

@media screen and (max-width: 767px) {
  .box-mini {
    margin: 10px;
  }
}

.block-mini {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .block-mini {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.blockT-mini {
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .blockT-mini {
    margin-top: 10px;
  }
}

.blockR-mini {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .blockR-mini {
    margin-right: 10px;
  }
}

.blockB-mini {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .blockB-mini {
    margin-bottom: 10px;
  }
}

.blockL-mini {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .blockL-mini {
    margin-left: 10px;
  }
}

.box-minimum {
  margin: 5px;
}

@media screen and (max-width: 767px) {
  .box-minimum {
    margin: 5px;
  }
}

.block-minimum {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .block-minimum {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.blockT-minimum {
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .blockT-minimum {
    margin-top: 5px;
  }
}

.blockR-minimum {
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .blockR-minimum {
    margin-right: 5px;
  }
}

.blockB-minimum {
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .blockB-minimum {
    margin-bottom: 5px;
  }
}

.blockL-minimum {
  margin-left: 5px;
}

@media screen and (max-width: 767px) {
  .blockL-minimum {
    margin-left: 5px;
  }
}

.box-zero {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .box-zero {
    margin: 0;
  }
}

.block-zero {
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .block-zero {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.blockT-zero {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .blockT-zero {
    margin-top: 0;
  }
}

.blockB-zero {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .blockB-zero {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .box-base-mdMin {
    margin: 20px;
  }
  .block-base-mdMin {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blockT-base-mdMin {
    margin-top: 20px;
  }
  .blockR-base-mdMin {
    margin-right: 20px;
  }
  .blockB-base-mdMin {
    margin-bottom: 20px;
  }
  .blockL-base-mdMin {
    margin-left: 20px;
  }
  .box-section03-mdMin {
    margin: 150px;
  }
  .block-section03-mdMin {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  .blockT-section03-mdMin {
    margin-top: 150px;
  }
  .blockR-section03-mdMin {
    margin-right: 150px;
  }
  .blockB-section03-mdMin {
    margin-bottom: 150px;
  }
  .blockL-section03-mdMin {
    margin-left: 150px;
  }
  .box-section02-mdMin {
    margin: 120px;
  }
  .block-section02-mdMin {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .blockT-section02-mdMin {
    margin-top: 120px;
  }
  .blockR-section02-mdMin {
    margin-right: 120px;
  }
  .blockB-section02-mdMin {
    margin-bottom: 120px;
  }
  .blockL-section02-mdMin {
    margin-left: 120px;
  }
  .box-section-mdMin {
    margin: 100px;
  }
  .block-section-mdMin {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .blockT-section-mdMin {
    margin-top: 100px;
  }
  .blockB-section-mdMin {
    margin-bottom: 100px;
  }
  .blockL-section-mdMin {
    margin-left: 100px;
  }
  .blockR-section-mdMin {
    margin-right: 100px;
  }
  .box-contents03-mdMin {
    margin: 60px;
  }
  .block-contents03-mdMin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .blockT-contents03-mdMin {
    margin-top: 60px;
  }
  .blockB-contents03-mdMin {
    margin-bottom: 60px;
  }
  .blockL-contents03-mdMin {
    margin-left: 60px;
  }
  .blockR-contents03-mdMin {
    margin-right: 60px;
  }
  .box-contents02-mdMin {
    margin: 50px;
  }
  .block-contents02-mdMin {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .blockT-contents02-mdMin {
    margin-top: 50px;
  }
  .blockB-contents02-mdMin {
    margin-bottom: 50px;
  }
  .blockL-contents02-mdMin {
    margin-left: 50px;
  }
  .blockR-contents02-mdMin {
    margin-right: 50px;
  }
  .box-contents-mdMin {
    margin: 40px;
  }
  .block-contents-mdMin {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .blockT-contents-mdMin {
    margin-top: 40px;
  }
  .blockR-contents-mdMin {
    margin-right: 40px;
  }
  .blockB-contents-mdMin {
    margin-bottom: 40px;
  }
  .blockL-contents-mdMin {
    margin-left: 40px;
  }
  .box-normal-mdMin {
    margin: 30px;
  }
  .block-normal-mdMin {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blockT-normal-mdMin {
    margin-top: 30px;
  }
  .blockR-normal-mdMin {
    margin-right: 30px;
  }
  .blockB-normal-mdMin {
    margin-bottom: 30px;
  }
  .blockL-normal-mdMin {
    margin-left: 30px;
  }
  .box-mini-mdMin {
    margin: 10px;
  }
  .block-mini-mdMin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blockT-mini-mdMin {
    margin-top: 10px;
  }
  .blockR-mini-mdMin {
    margin-right: 10px;
  }
  .blockB-mini-mdMin {
    margin-bottom: 10px;
  }
  .blockL-mini-mdMin {
    margin-left: 10px;
  }
  .box-semi-mdMin {
    margin: 20px;
  }
  .block-semi-mdMin {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blockT-semi-mdMin {
    margin-top: 20px;
  }
  .blockR-semi-mdMin {
    margin-right: 20px;
  }
  .blockB-semi-mdMin {
    margin-bottom: 20px;
  }
  .blockL-semi-mdMin {
    margin-left: 20px;
  }
  .box-minimum-mdMin {
    margin: 5px;
  }
  .block-minimum-mdMin {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .blockT-minimum-mdMin {
    margin-top: 5px;
  }
  .blockR-minimum-mdMin {
    margin-right: 5px;
  }
  .blockB-minimum-mdMin {
    margin-bottom: 5px;
  }
  .blockL-minimum-mdMin {
    margin-left: 5px;
  }
  .box-zero-mdMin {
    margin: 0;
  }
  .block-zero-mdMin {
    margin-top: 0;
    margin-bottom: 0;
  }
  .blockT-zero-mdMin {
    margin-top: 0;
  }
  .blockB-zero-mdMin {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .box-base-mdMax {
    margin: 20px;
  }
  .block-base-mdMax {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blockT-base-mdMax {
    margin-top: 20px;
  }
  .blockR-base-mdMax {
    margin-right: 20px;
  }
  .blockB-base-mdMax {
    margin-bottom: 20px;
  }
  .blockL-base-mdMax {
    margin-left: 20px;
  }
  .box-section03-mdMax {
    margin: 100px;
  }
  .block-section03-mdMax {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .blockT-section03-mdMax {
    margin-top: 100px;
  }
  .blockR-section03-mdMax {
    margin-right: 100px;
  }
  .blockB-section03-mdMax {
    margin-bottom: 100px;
  }
  .blockL-section03-mdMax {
    margin-left: 100px;
  }
  .box-section02-mdMax {
    margin: 75px;
  }
  .block-section02-mdMax {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .blockT-section02-mdMax {
    margin-top: 75px;
  }
  .blockR-section02-mdMax {
    margin-right: 75px;
  }
  .blockB-section02-mdMax {
    margin-bottom: 75px;
  }
  .blockL-section02-mdMax {
    margin-left: 75px;
  }
  .box-section-mdMax {
    margin: 50px;
  }
  .block-section-mdMax {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .blockT-section-mdMax {
    margin-top: 50px;
  }
  .blockR-section-mdMax {
    margin-right: 50px;
  }
  .blockB-section-mdMax {
    margin-bottom: 50px;
  }
  .blockL-section-mdMax {
    margin-left: 50px;
  }
  .box-contents03-mdMax {
    margin: 40px;
  }
  .block-contents03-mdMax {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .blockT-contents03-mdMax {
    margin-top: 40px;
  }
  .blockR-contents03-mdMax {
    margin-right: 40px;
  }
  .blockB-contents03-mdMax {
    margin-bottom: 40px;
  }
  .blockL-contents03-mdMax {
    margin-left: 40px;
  }
  .box-contents02-mdMax {
    margin: 35px;
  }
  .block-contents02-mdMax {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .blockT-contents02-mdMax {
    margin-top: 35px;
  }
  .blockR-contents02-mdMax {
    margin-right: 35px;
  }
  .blockB-contents02-mdMax {
    margin-bottom: 35px;
  }
  .blockL-contents02-mdMax {
    margin-left: 35px;
  }
  .box-contents-mdMax {
    margin: 30px;
  }
  .block-contents-mdMax {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .blockT-contents-mdMax {
    margin-top: 30px;
  }
  .blockR-contents-mdMax {
    margin-right: 30px;
  }
  .blockB-contents-mdMax {
    margin-bottom: 30px;
  }
  .blockL-contents-mdMax {
    margin-left: 30px;
  }
  .box-normal-mdMax {
    margin: 20px;
  }
  .block-normal-mdMax {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blockT-normal-mdMax {
    margin-top: 20px;
  }
  .blockR-normal-mdMax {
    margin-right: 20px;
  }
  .blockB-normal-mdMax {
    margin-bottom: 20px;
  }
  .blockL-normal-mdMax {
    margin-left: 20px;
  }
  .box-semi-mdMax {
    margin: 15px;
  }
  .block-semi-mdMax {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .blockT-semi-mdMax {
    margin-top: 15px;
  }
  .blockR-semi-mdMax {
    margin-right: 15px;
  }
  .blockB-semi-mdMax {
    margin-bottom: 15px;
  }
  .blockL-semi-mdMax {
    margin-left: 15px;
  }
  .box-mini-mdMax {
    margin: 10px;
  }
  .block-mini-mdMax {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blockT-mini-mdMax {
    margin-top: 10px;
  }
  .blockR-mini-mdMax {
    margin-right: 10px;
  }
  .blockB-mini-mdMax {
    margin-bottom: 10px;
  }
  .blockL-mini-mdMax {
    margin-left: 10px;
  }
  .box-minimum-mdMax {
    margin: 5px;
  }
  .block-minimum-mdMax {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .blockT-minimum-mdMax {
    margin-top: 5px;
  }
  .blockR-minimum-mdMax {
    margin-right: 5px;
  }
  .blockB-minimum-mdMax {
    margin-bottom: 5px;
  }
  .blockL-minimum-mdMax {
    margin-left: 5px;
  }
  .box-zero-mdMax {
    margin: 0;
  }
  .block-zero-mdMax {
    margin-top: 0;
    margin-bottom: 0;
  }
  .blockT-zero-mdMax {
    margin-top: 0;
  }
  .blockB-zero-mdMax {
    margin-bottom: 0;
  }
}

.u-center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .u-center-mdMin {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .u-center-mdMax {
    text-align: center;
  }
}

.u-right {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .u-right-mdMin {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .u-right-mdMax {
    text-align: right;
  }
}

.u-left {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .u-left-mdMin {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .u-left-mdMax {
    text-align: left;
  }
}

.u-asCenter {
  align-self: center;
}

.u-aiCenter {
  align-items: center;
}

@media screen and (min-width: 768px) {
  .u-aiCenter-mdMin {
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .u-aiCenter-mdMax {
    align-items: center;
  }
}

.u-aiBaseline {
  align-items: baseline;
}

@media screen and (min-width: 768px) {
  .u-aiBaseline-mdMin {
    align-items: baseline;
  }
}

@media screen and (max-width: 767px) {
  .u-aiBaseline-mdMax {
    align-items: baseline;
  }
}

.u-jcFlexEnd {
  justify-content: flex-end;
}

.u-jcCenter {
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .u-jcCenter-mdMin {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .u-jcCenter-mdMax {
    justify-content: center;
  }
}

.u-jcSpaceBetween {
  justify-content: space-between;
}

.u-jcSpaceAround {
  justify-content: space-around;
}

.u-fwNoWrap {
  flex-wrap: nowrap;
}

@media screen and (min-width: 768px) {
  .u-fwNoWrap-mdMin {
    flex-wrap: nowrap;
  }
}

.u-fdRowReverse {
  flex-direction: row-reverse;
}

@media screen and (min-width: 768px) {
  .u-fdRowReverse-mdMin {
    flex-direction: row-reverse;
  }
}

.u-pdZero {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .u-pdZero-mdMax {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .u-pdZero-mdMin {
    padding: 0;
  }
}

.u-ffGothic {
  font-family: "Roboto", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", "Meiryo, Osaka", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.u-ffMincho {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.u-fcBase {
  color: #b60000;
}

.u-fcWhite {
  color: #fff;
}

.u-fcBlack {
  color: #000;
}

.u-fcBlackLight {
  color: #333;
}

.u-fcGray {
  color: #ccc;
}

.u-fcGray02 {
  color: #777;
}

.u-fwLight {
  font-weight: 300;
}

.u-fwNormal {
  font-weight: 400;
}

.u-fwBolder {
  font-weight: 500;
}

.u-fwBold {
  font-weight: 700;
}

.u-fsBig01 {
  font-size: 110%;
}

.u-fsBig02 {
  font-size: 120%;
}

.u-fsBig03 {
  font-size: 130%;
}

.u-fsBig04 {
  font-size: 140%;
}

.u-fsBig05 {
  font-size: 150%;
}

.u-fsBig06 {
  font-size: 160%;
}

.u-fsBig07 {
  font-size: 170%;
}

.u-fsBig08 {
  font-size: 180%;
}

.u-fsBig09 {
  font-size: 190%;
}

.u-fsBig010 {
  font-size: 200%;
}

.u-fsSmall01 {
  font-size: 95%;
}

.u-fsSmall02 {
  font-size: 90%;
}

.u-fsSmall03 {
  font-size: 85%;
}

.u-fsSmall04 {
  font-size: 80%;
}

.u-fsSmall05 {
  font-size: 75%;
}

.u-fsSmall06 {
  font-size: 70%;
}

.u-fsSmall07 {
  font-size: 65%;
}

.u-fsSmall08 {
  font-size: 60%;
}

.u-fsSmall09 {
  font-size: 55%;
}

.u-fsSmall010 {
  font-size: 50%;
}

.u-ocZero {
  opacity: 0;
}

.u-lhZero {
  line-height: 1.0;
}

.u-lhLow {
  line-height: 1.3608;
}

.u-lhMiddle {
  line-height: 1.5606;
}

.u-lhHigh {
  line-height: 1.9998;
}

.u-lhHighest {
  line-height: 3.0006;
}

.u-lsZero {
  letter-spacing: 0;
}

.u-lsShort {
  letter-spacing: .05em;
}

.u-lsNormal {
  letter-spacing: .1em;
}

.u-lsLong {
  letter-spacing: .15em;
}

.u-ofHidden {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .u-ofHidden-mdMin {
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .u-ofHidden-mdMax {
    overflow: hidden;
  }
}

.u-dpBlock {
  display: block;
}

.u-dpInlineBlock {
  display: inline-block;
}

/*
.u-tdUnderLine {
text-decoration: underline;
}
//
.u-fsItalic {
font-style: italic;
}

//
.u-radiusSmall {
border-radius: 5px;
@include mq(md, max) {
border-radius: 3px;
}
}
.u-radiusMedium {
border-radius: 10px;
@include mq(md, max) {
border-radius: 5px;
}
}
.u-radiusLarge {
border-radius: 15px;
@include mq(md, max) {
border-radius: 8px;
}
}
.u-vaMiddle {
vertical-align: middle;
}


*/
.disNone {
  display: none;
}

@media screen and (min-width: 768px) {
  .disNone-mdMin {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .disNone-mdMax {
    display: none;
  }
}

.disNone-active.is-active {
  display: none;
}

.desktop .pcNone {
  display: none;
}

.smartphone .spNone {
  display: none;
}

.tablet .tabNone {
  display: none;
}

.imp-block-zero {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
